import React from 'react';
import '../../css/home/CTA.css';
import { Link } from 'react-router-dom';


const CTA = () => {
  return (
    <div className="cta-section">
      <h1 className="cta-heading">Elevate your brand with us.</h1>
      
      <div className="cta-subtext">
        <div className="cta-subtitle">Your success is our mission.<b className='subtext-item'>Join Romulus Media today.</b></div>
      </div>
      
      <div className="cta-button-container">
      <Link to="/brands">
      <button className="cta-button">Reach Students Today</button>
      </Link>
      </div>
    </div>
  );
};

export default CTA;
