import React from 'react';
import Navbar from '../Navbar'; 
import Footer from '../Footer';
import BrandForm from '../brand/BrandForm';

function BrandPage() {
    return (
        <>
            <Navbar />
            <BrandForm />
            <Footer />
        </>
    );
}

export default BrandPage;
