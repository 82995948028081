import React from 'react';
import '../../css/home/ImageSection.css';
import HomeImage from '../../img/home/homepage.jpg';

const ImageSection = () => {
  return (
    <section className="image-section">
      <img src={HomeImage} alt="Homepage" className="image-placeholder" />       
    </section>
  );
};

export default ImageSection;
