import React from 'react';
import { Link } from 'react-router-dom';
import '../../css/home/Hero.css';

const Hero = () => {
  return (
    <section className="hero">
      <h1 className="hero-title">connecting brands with colleges.</h1>
      <p className="hero-subtitle">Building loyalties early, <b className='hero-subtitle-2'>when they matter most.</b></p>
      <div className="hero-buttons">
        <Link to="/brands">
        <button className="hero-btn request-access-btn">Get Started</button>
        </Link>
        <Link to="/creators">
          <button className="hero-btn join-creators-btn">Join Our Students</button>
        </Link>
      </div>
    </section>
  );
};

export default Hero;

