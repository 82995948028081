import React from 'react';
import '../css/Footer.css';
import RMLogo from '../img/logos/RMnavy.png'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="footer-logo">
          <img src={RMLogo} alt="Logo" />
        </div>

        {/* <div className="newsletter">
          <input 
            type="text" 
            className="newsletter-input" 
            placeholder="Email" 
          />
          <button className="subscribe-button">Subscribe</button>
          <p><br></br>Stay updated with our newsletter!</p>
        </div> */}
      </div>

      <div className="footer-links">
        <div className="footer-column">
          <h4>Product</h4>
          <ul>
            {/* <li>Brands</li>
            <li>Services</li> */}
            <li><a href="/brands" class="custom-link">Get Started</a></li>
            {/* <li>Sign up</li>
            <li>Log In</li>
            <li>→ Get Started</li> */}
          </ul>
        </div>

        <div className="footer-column">
          <h4>Company</h4>
          <ul>
            {/* <li>About</li>
            <li>Our Mission</li>
            <li>Careers</li> */}
            <li><a href="/brands" class="custom-link">Contact Us</a></li>
            {/* <li>Privacy Policy</li>
            <li>Terms of Service</li> */}
          </ul>
        </div>

        <div className="footer-column">
          <h4>Resources</h4>
          <ul>
            <li><a href="/" class="custom-link">Romulus Media</a></li>
            {/* <li>Help Center</li>
            <li>Newsletter</li>
            <li>Feedback</li>
            <li>General</li> */}
          </ul>
        </div>

        <div className="footer-column">
          <h4>Social</h4>
          <ul>
            <li>
              <a href="https://www.instagram.com/romulusmedia"
                class="custom-link">Instagram
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/romulus-media"
                class="custom-link">LinkedIn
              </a>
            </li>
            <li>
              <a href="https://www.tiktok.com/romulusmedia"
                class="custom-link">TikTok
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <p className='copyright'>Copyright © 2024 Romulus Media. All rights reserved.</p>
        <div className="footer-bottom-right">
        </div>
      </div>
    </footer>
  );
};

export default Footer;
