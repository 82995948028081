import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/home/Hero';
import ImageSection from './components/home/ImageSection';
import Logos from './components/home/Logos';
import Students from './components/home/Students';
// import BlogCarousel from './components/home/BlogCarousel';
import Services from './components/home/Services';
import CTA from './components/home/CTA';
import Footer from './components/Footer';
import CreatorPage from './components/creator/CreatorPage'; 
import BrandPage from './components/brand/BrandPage'; 
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        {/* Home Page */}
        <Route path="/" element={
          <div>
            <Navbar />
            <Hero />
            <ImageSection />
            <Logos />
            <Services />
            <Students />
            {/* <BlogCarousel /> */}
            <CTA />
            <Footer />
          </div>
        } />

(
   
        {/* Creator Page */}
        <Route path="/creators" element={<CreatorPage />} />
        {/* Brand Page */}
        <Route path="/brands" element={<BrandPage />} />
      </Routes>
    </Router>
  );
}
  
export default App;
