import React, { useState } from 'react';
import '../../css/creator/CreatorForm.css';
const API_URL = 'https://rm-backend-service-505761517154.us-central1.run.app/apply';

const CreatorForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    interest: [],
    school: '',
    gradYear: '',
    major: '',
    visa: '',
    instagram: '',
    instagramFollowers: '',
    tiktok: '',
    tiktokFollowers: '',
    howDidYouHear: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInterestChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevState) => {
      if (checked) {
        return { ...prevState, interest: [...prevState.interest, value] };
      } else {
        return { ...prevState, interest: prevState.interest.filter((interest) => interest !== value) };
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        alert('Application submitted successfully!');
      } else {
        console.error('Failed to submit the application.');
      }
    } catch (error) {
      console.error('Error:', error);
      console.error('Error submitting form');
    }
  };

  return (
    <div className="creator-form-body">
      <div className="apply-container">
        <div className="form-section-creator">
          <h2>Apply to Romulus Media today!</h2>
          <ol>Enter your details so we can learn more about you and share brand opportunities!</ol>
          <br />
          <form className="creator-form" onSubmit={handleSubmit}>
            <label>
              First Name
              <input
                type="text"
                name="firstName"
                placeholder="First name"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Last Name
              <input
                type="text"
                name="lastName"
                placeholder="Last name"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Email Address
              <input
                type="email"
                name="email"
                placeholder="you@email.com"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Phone Number
              <input
                type="tel"
                name="phone"
                placeholder="123-456-7890"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </label>

            <fieldset>
              <legend>Which of these areas are you interested in?</legend>
              <label>
                <input
                  type="checkbox"
                  name="interest"
                  value="Influencer Marketing"
                  checked={formData.interest.includes("Influencer Marketing")}
                  onChange={handleInterestChange}
                />
                Influencer Marketing
              </label>
              <label>
                <input
                  type="checkbox"
                  name="interest"
                  value="Content Creation"
                  checked={formData.interest.includes("Content Creation")}
                  onChange={handleInterestChange}
                />
                Content Creation
              </label>
              <label>
                <input
                  type="checkbox"
                  name="interest"
                  value="Ambassador Programs"
                  checked={formData.interest.includes("Ambassador Programs")}
                  onChange={handleInterestChange}
                />
                Ambassador Programs
              </label>
              <label>
                <input
                  type="checkbox"
                  name="interest"
                  value="On Campus Events"
                  checked={formData.interest.includes("On Campus Events")}
                  onChange={handleInterestChange}
                />
                On Campus Events
              </label>
              <label>
                <input
                  type="checkbox"
                  name="interest"
                  value="Student Focus Groups"
                  checked={formData.interest.includes("Student Focus Groups")}
                  onChange={handleInterestChange}
                />
                Student Focus Groups
              </label>
            </fieldset>

            <label>
              What school do you attend?
              <input
                type="text"
                name="school"
                placeholder="School"
                value={formData.school}
                onChange={handleChange}
                required
              />
            </label>

            <label>
              When do you graduate?
              <input
                type="number"
                name="gradYear"
                placeholder="Graduation Year"
                value={formData.gradYear}
                onChange={handleChange}
                required
              />
            </label>

            <label>
              What is your major?
              <input
                type="text"
                name="major"
                placeholder="Major"
                value={formData.major}
                onChange={handleChange}
                required
              />
            </label>

            <select name="visa" value={formData.visa} onChange={handleChange} required>
              <option value="">Are you eligible to work in the US?</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>

            <label>
              Instagram URL
              <input
                type="text"
                name="instagram"
                placeholder="https://instagram.com/yourusername"
                value={formData.instagram}
                onChange={handleChange}
                required
              />
            </label>

            <label>
              How many Instagram followers do you have?
              <input
                type="number"
                name="instagramFollowers"
                placeholder="500"
                value={formData.instagramFollowers}
                onChange={handleChange}
                required
              />
            </label>

            <label>
              TikTok URL
              <input
                type="text"
                name="tiktok"
                placeholder="https://tiktok.com/yourusername"
                value={formData.tiktok}
                onChange={handleChange}
                required
              />
            </label>

            <label>
              How many TikTok followers do you have?
              <input
                type="number"
                name="tiktokFollowers"
                placeholder="500"
                value={formData.tiktokFollowers}
                onChange={handleChange}
                required
              />
            </label>

            <select name="howDidYouHear" value={formData.howDidYouHear} onChange={handleChange} required>
              <option value="">How did you hear about us?</option>
              <option value="tiktok">TikTok</option>
              <option value="instagram">Instagram</option>
              <option value="linkedin">LinkedIn</option>
              <option value="friendfamily">Friend/Family</option>
              <option value="google">Google</option>
              <option value="school">School/Campus Organization</option>
              <option value="romulusmedia">Romulus Media contacted me</option>
            </select>

            <button type="submit" className="submit-btn">Apply</button>
          </form>
        </div>
        <div className="info-section">
          <ol>
            <li className="step-item">
              <div className="step-content">
                <br></br>
                <h3><span className="step-number-creator">1</span>Apply to our network</h3>
                <li>Fill out our application so we can find curated opportunities for you.</li>
              </div>
            </li>
            <li className="step-item">
              <div className="step-content">
                <h3><span className="step-number-creator">2</span>Interview</h3>
                <li>We will reach out to schedule a call to...</li>
                <ul>
                  <li>✓ Discuss your goals</li>
                  <li>✓ Understand your skills and interests</li>
                  <li>✓ Determine which of our many programs would be a perfect fit</li>
                </ul>
              </div>
            </li>
            <li className="step-item">
              <div className="step-content">
                <h3><span className="step-number-creator">3</span>Begin campaigning!</h3>
                <li>Gain marketing skills and expand your network throughout our programs with many brands.</li>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default CreatorForm;
