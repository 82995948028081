import React, { useState } from 'react';
import '../../css/brand/BrandForm.css';

const API_URL = 'https://rm-backend-service-505761517154.us-central1.run.app/submit';

const BrandForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Form submitted successfully!');
      } else {
        console.error('Failed to submit form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="brand-form-body">
      <div className="get-started-container">
        <div className="info-section-brand">
          <ol>
            <li className="step-item-brand">
              <div className="step-content">
                <div>
                  <h3><span className="step-number">1</span>Share your details</h3>
                  <p>Input your contact information so we can connect.</p>
                </div>
              </div>
            </li>
            <li className="step-item-brand">
              <div className="step-content">
                <div>
                  <h3><span className="step-number">2</span>Schedule a demo</h3>
                  <p>We will reach out to you to schedule a call to...</p>
                  <ul>
                    <li>✓ Discuss your brand.</li>
                    <li>✓ Understand your goals</li>
                    <li>✓ Begin designing a curated campaign for you.</li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="step-item-brand">
              <div className="step-content">
                <div>
                  <h3><span className="step-number">3</span>Launch your campaign</h3>
                  <p>Watch your brand grow with us!</p>
                </div>
              </div>
            </li>
          </ol>
        </div>
        <div className="form-section">
          <h2>Let's get started!</h2>
          <p>Share your info so we can begin planning your first campaign!</p>
          <form className="brand-form" onSubmit={handleSubmit}>
            <label>
              Work email
              <input
                type="email"
                name="email"
                placeholder="you@company.com"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              First name
              <input
                type="text"
                name="firstName"
                placeholder="First name"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Last name
              <input
                type="text"
                name="lastName"
                placeholder="Last name"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Message
              <textarea
                name="message"
                placeholder="Say hi!"
                value={formData.message}
                onChange={handleChange}
                rows="4"
              />
            </label>
            <button type="submit" className="submit-btn-brand">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BrandForm;
