import React from 'react';
import '../../css/home/Services.css';
import AmbassadorIcon from '../../img/services/ambassador.jpg'; 
import ContentCreationIcon from '../../img/services/content.jpg'; 
import FocusGroupsIcon from '../../img/services/focus.jpg'; 
import { Link } from 'react-router-dom';

const Services = () => {
  return (
    <div className="services-container">
      <div className="services-grid">
        <div className="service">
          <img src={AmbassadorIcon} alt="Ambassador Programs" className="service-icon cta-image" />
          <h3 className="service-title">Field Marketing & Brand Ambassadors</h3>
          <p className="service-description">
          Romulus Media drives loyalties with ambassadorship through on-campus events, 
          field marketing, strategic student-organization partnerships, and more.
          </p>
        </div>

        <div className="service">
          <img src={ContentCreationIcon} alt="Content Creation" className="service-icon cta-image" />
          <h3 className="service-title">Influencer Marketing & Consumer Generated Content</h3>
          <p className="service-description">
          Partner with student influencers to create authentic, shareable content that connects 
          and builds brand loyalty.
          </p>
        </div>

        <div className="service">
          <img src={FocusGroupsIcon} alt="Focus Groups" className="service-icon cta-image" />
          <h3 className="service-title">Focus Groups & Product Testing</h3>
          <p className="service-description">
          Gain valuable insights with student-led focus groups and product testing to refine your 
          products, campaigns, messaging, and other key pivots. 
          </p>
        </div>
      </div>

      <div className="cta-button-container">
      <Link to="/brands">
      <button className="cta-button">Learn More</button>
      </Link>
      </div>
    </div>
  );
};

export default Services;
