import React from 'react';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import '../css/Navbar.css';
import logo from '../img/logos/RM_banner.png';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        {/* Wrap the logo in Link to navigate to the homepage */}
        <Link to="/">
          <img src={logo} alt="Romulus Media" className="main-logo" />
        </Link>
      </div>
      
      {/* Optional: Uncomment if you want to add navigation links in the future */}
      {/* <ul className="navbar-links">
        <li><a href="#forstudents">For Students</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#casestudies">Case Studies</a></li>
        <li><a href="#pricing">Pricing</a></li>
      </ul> */}
      
      <div className="navbar-actions">
        {/* Optional: Uncomment to add sign-in link */}
        {/* <a href="/signin" className="signin">Sign in</a> */}
        <Link to="/brands">
          <button className="request-access">Get Started</button>
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
