import React from 'react';
import '../../css/creator/Benefits.css';

import PaidImage from '../../img/creator/benefits/paid.png';
import FlexibleImage from '../../img/creator/benefits/flexible.png';
import NetworkingImage from '../../img/creator/benefits/network.png';

function Benefits() {
  const benefitsData = [
    {
      id: 1,
      image: PaidImage, 
      imageAlt: "Paid Benefit",
      title: "Paid",
      description: "We offer a wide variety of paid opportunities, ranging from ambassador programs, content creation, and much more!"
    },
    {
      id: 2,
      image: FlexibleImage, 
      imageAlt: "Flexible Benefit",
      title: "Flexible",
      description: "Our marketing opportunities are immensely flexible, allowing you to choose the hours that work for you from anywhere."
    },
    {
      id: 3,
      image: NetworkingImage, 
      imageAlt: "Networking Benefit",
      title: "Networking",
      description: "Romulus Media provides our creators with access to a large-scale network filled with big brands and other creators from across the world."
    }
  ];

  return (
    <div className="benefits-section">
      {benefitsData.map((benefit) => (
        <div className="benefit-item" key={benefit.id}>
          <div className="benefit-icon">
            <div className="circle">
              <img src={benefit.image} alt={benefit.imageAlt} className="circle-image" />
            </div>
          </div>
          <div className="benefit-content">
            <h3 className="benefit-title">{benefit.title}</h3>
            <p className="benefit-description">{benefit.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Benefits;
