import React, { useRef } from 'react';
import '../../css/creator/CreatorPage.css'; 
import Navbar from '../Navbar'; 
import Footer from '../Footer';
import CreatorImage from '../../img/creator/creators.jpg';
import Benefits from '../creator/Benefits';
import CreatorForm from '../creator/CreatorForm';

function CreatorPage() {
  const formStartRef = useRef(null); // Reference to the start of the form section
  const scrollOffset = 50; // Define offset here

  // Scroll function using the single offset variable
  const scrollToFormStart = () => {
    window.scrollTo({
      top: formStartRef.current.offsetTop - scrollOffset,
      behavior: 'smooth',
    });
  };

  return (
    <div className="creators-page">
      <Navbar />
     
      <div className="creator-section">
        <h2 className="creator-section-title">Welcome Creators</h2>
        <img src={CreatorImage} alt="Creators" className="creator-image-placeholder" />  
        <div>
          <p>Interested in pursuing marketing? Want to collaborate with world-renowned brands?</p>
        </div>
        <div>
          <p>Join Romulus Media’s Student Ambassador Network and become the face of world-renowned 
            brands on your campus! Gain valuable marketing experience, earn competitive pay, 
            and have fun promoting exciting products and services to your fellow students.
          </p>
        </div>
        {/* "Apply Now" button */}
        <button className="creator-section-button" onClick={scrollToFormStart}>Apply Now</button>
      </div> 

      <Benefits />

      <div className="creator-section-2">
        {/* "Apply to the Student Network" button */}
        <button className="creator-section-button" onClick={scrollToFormStart}><b>Apply to the Student Network</b></button>
      </div> 
      
      {/* Scroll target for start of form */}
      <div ref={formStartRef}></div>

      <CreatorForm />

      <Footer />     
    </div>
  );
}

export default CreatorPage;
