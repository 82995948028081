import React from 'react';
import { Link } from 'react-router-dom'; // Import Link
import '../../css/home/Students.css';
import Student from '../../img/students/students.png';

const Students = () => {
  return (
    <div className="dark-students-section-container">
      <section className="dark-students-section">
        <div className="students-content">
          <h3 className="students-subtitle">STUDENTS</h3>
          <h1 className="students-title">
            Join the Campaigns.<br />
          </h1>
          <p className="students-description">
            Gain exclusive opportunities 
            to collaborate with some of the world’s leading brands.
            <br/> <br/> 
            Whether you’re a marketing student, content creator, 
            former brand ambassador, or aspiring influencer, 
            <br/> 
            <b> your next big move starts here.</b>
          </p>
          {/* Use Link instead of button */}
          <Link to="/creators">
            <button className="students-button">
              Apply to the Student Network
            </button>
          </Link>
        </div>
        <div className="students-cards">
          <img 
            src={Student} 
            alt="Student Network" 
            className="students-network-image" 
          />
        </div>
      </section>
    </div>
  );
};

export default Students;
